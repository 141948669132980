<template>
  <div class="w-100 d-flex flex-column h-100">
    <base-tool-bar title="Divers" subtitle="Informations complémentaires"></base-tool-bar>
    <base-form class="mr-md-3" @click="updateCollaborateur" :loading="loading">
      <base-form-group title="Infos" dense icon="user">
        <base-form-row row>
          <base-input
            v-model="collaborateur_type_doc"
            inputText="Type de document (PASS / CNI)"
            :errors="feedback.collaborateur_type_doc"
          ></base-input>
          <base-inputDatePicker
            v-model="collaborateur_doc_validite"
            inputText="Validité des documents (PASS / CNI)"
            :errors="feedback.collaborateur_doc_validite"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input
            v-model="collaborateur_etranger_num_ordre"
            inputText="Numéro d'ordre (étranger)"
            :errors="feedback.collaborateur_etranger_num_ordre"
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <base-input
            v-model="collaborateur_etranger_type_doc"
            inputText="Type de document (étranger)"
            :errors="feedback.collaborateur_etranger_type_doc"
          ></base-input>
          <base-inputDatePicker
            v-model="collaborateur_etranger_validite"
            inputText="Validité des documents (étranger)"
            :errors="feedback.collaborateur_etranger_validite"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row class="pt-3">
          <Checkbox
            id="1"
            v-model="collaborateur_handicape"
            text="Travailleur handicapé"
            :errors="feedback.collaborateur_handicape"
            :iconActive="true"
          ></Checkbox>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker
            v-model="collaborateur_rqth_validite"
            inputText="RQTH (Date de validité)"
            :errors="feedback.collaborateur_rqth_validite"
          ></base-inputDatePicker>
        </base-form-row>

        <base-form-row>
          <base-input
            v-model="collaborateur_carnet_acces"
            inputText="N° de carnet d'accès (CARNAX)"
            :errors="feedback.collaborateur_carnet_acces"
          ></base-input>
          <base-input
            v-model="collaborateur_carte_orange"
            inputText="N° de carte orange (suivi médical)"
            :errors="feedback.collaborateur_carte_orange"
          ></base-input>
        </base-form-row>
        <base-form-row>
          <base-input
            v-model="collaborateur_badge_autre"
            inputText="Autre badge d'accès"
            :errors="feedback.collaborateur_badge_autre"
          ></base-input>
        </base-form-row>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import Checkbox from "@/components/bases/Checkbox.vue";

export default {
  components: {
    BaseInput,
    BaseInputDatePicker,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
    BaseToolBar,
    Checkbox
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null
    };
  },
  props: {
    contextData: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  methods: {
    ...mapActions({
      _updateCollaborateur: "collaborateurs/updateCollaborateur"
    }),
    updateCollaborateur: function() {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur.collaborateur_etranger_num_ordre",
      "collaborateur.collaborateur_etranger_type_doc",
      "collaborateur.collaborateur_etranger_validite",
      "collaborateur.collaborateur_type_doc",
      "collaborateur.collaborateur_doc_validite",
      "collaborateur.collaborateur_carnet_acces",
      "collaborateur.collaborateur_carte_orange",
      "collaborateur.collaborateur_badge_autre",
      "collaborateur.collaborateur_handicape",
      "collaborateur.collaborateur_rqth_validite"
    ])
  }
};
</script>
<style lang="css"></style>