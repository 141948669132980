<template>
  <div class="p-2 p-md-3">
    <div class="form-group-title">
      <div class="d-flex flex-row">
        <base-icon :name="icon" v-if="icon" class="mr-2"></base-icon>
        <h5 class="m-0">{{ title }}</h5>
      </div>
    </div>
    <div class="form-group" :class="{ 'form-group-dense': dense }">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: { BaseIcon },
  props: {
    title: {
      type: String,
      required: false,
      default: "Title"
    },
    dense: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "box"
    }
  }
};
</script>
<style>
@media (min-width: 1200px) {
  .form-group > div {
    margin-top: 2rem;
  }

  .form-group > div:first-child {
    margin-top: 1rem;
  }

  .form-group-dense > div {
    margin-top: 0 !important;
  }
}
@media (max-width: 1200px) {
}
</style>